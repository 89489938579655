import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Main",
        component: () => import("@/views/About.vue")
      },
      {
        path: "/office",
        name: "Office",
        meta: { title: "勤怠登録（事業所）" },
        component: () => import("@/views/Office.vue")
      },
      {
        path: "/attendance",
        name: "Attendance",
        component: () => import("@/views/AttendanceDetails.vue")
      },
      {
        path: "/shifttable",
        name: "ShiftTable",
        component: () => import("@/views/ShiftTable.vue")
      },
      {
        path: "/approval",
        name: "ApprovalList",
        component: () => import("@/views/ApprovalList.vue")
      },
      {
        path: "/approval/detail",
        name: "ApprovalDetails",
        component: () => import("@/views/ApprovalDetails.vue")
      },
      {
        path: "/approval-status",
        name: "ApprovalStatus",
        component: () => import("@/views/ApprovalStatus.vue")
      },
      {
        path: "/list/:name",
        name: "List",
        component: () => import("@/views/TableView/List.vue")
      },
      // {
      //   path: "/details/:name",
      //   name: "Details",
      //   component: () => import("@/views/TableView/Details.vue"),
      //   props: true
      // },
      {
        path: "/details/shift-pattern",
        name: "ShiftPatternDetails",
        component: () => import("@/views/TableView/ShiftPatternDetails.vue"),
        props: true
      },
      {
        path: "/details/code-group",
        name: "CodeGroupDetails",
        component: () => import("@/views/TableView/CodeGroupDetails.vue"),
        props: true
      },
      {
        path: "/details/code-master",
        name: "CodeMasterDetails",
        component: () => import("@/views/TableView/CodeMasterDetails.vue"),
        props: true
      },
      {
        path: "/details/shozoku-exp",
        name: "ShozokuExpDetails",
        component: () => import("@/views/TableView/ShozokuExpDetails.vue"),
        props: true
      },
      {
        path: "/details/shain-exp",
        name: "ShainExpDetails",
        component: () => import("@/views/TableView/ShainExpDetails.vue"),
        props: true
      },
      {
        path: "/details/daily-event",
        name: "DailyEventDetails",
        component: () => import("@/views/TableView/DailyEventDetails.vue"),
        props: true
      },
      {
        path: "/details/rely-to-shain",
        name: "RelyToShainDetails",
        component: () => import("@/views/TableView/RelyToShainDetails.vue"),
        props: true
      },
      {
        path: "/details/rely-to-shozoku",
        name: "RelyToShozokuDetails",
        component: () => import("@/views/TableView/RelyToShozokuDetails.vue"),
        props: true
      },
      {
        path: "/details/shozoku",
        name: "ShozokuGroup",
        component: () => import("@/views/TableView/ShozokuGroup.vue"),
        props: true
      },
      {
        path: "/details/shain",
        name: "ShainDetails",
        component: () => import("@/views/TableView/ShainDetails.vue"),
        props: true
      },
      {
        path: "/smile-linkage",
        name: "SmileLinkage",
        component: () => import("@/views/SmileLinkage.vue"),
        props: true
      },
      {
        path: "/shift-order",
        name: "ShiftOrder",
        component: () => import("@/views/ShiftOrder.vue"),
        props: true
      },
      {
        path: "/shift-pattern-order",
        name: "ShiftPatternOrder",
        meta: { title: "シフトパターン表示順" },
        component: () => import("@/views/ShiftPatternOrder.vue"),
        props: true
      },
      {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/debug",
        name: "Debug",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Debug.vue")
      },
      {
        path: "/for-calc",
        name: "ForCalc",
        meta: { title: "時間・給与計算" },
        component: () => import("@/views/ForCalcList.vue")
      },
      {
        path: "/qr-shozoku",
        name: "QrShozoku",
        meta: { title: "事業所QRコード" },
        component: () => import("@/views/QrShozoku.vue")
      },
      {
        path: "/qr-shain",
        name: "QrShain",
        meta: { title: "社員QRコード" },
        component: () => import("@/views/QrShain.vue")
      },
      {
        path: "/welfare/attendance-import",
        name: "WelfareAttendanceImport",
        meta: { title: "勤怠データ取込" },
        component: () => import("@/views/WelfareAttendanceImport.vue")
      },
      {
        path: "/welfare/attendance",
        name: "WelfareAttendanceDetails",
        meta: { title: "勤務表" },
        component: () => import("@/views/WelfareAttendanceDetails.vue")
      },
      {
        path: "/welfare/shifttable",
        name: "WelfareShiftTable",
        component: () => import("@/views/WelfareShiftTable.vue")
      },
      {
        path: "/welfare/meeting",
        name: "WelfareMeetingAdd",
        meta: { title: "ミーティング" },
        component: () => import("@/views/WelfareMeetingAdd.vue")
      },
      {
        path: "/welfare/training",
        name: "WelfareTrainingAdd",
        meta: { title: "研修" },
        component: () => import("@/views/WelfareTrainingAdd.vue")
      },
      {
        path: "/welfare/for-calc",
        name: "WelfareForCalc",
        meta: { title: "時間・給与計算" },
        component: () => import("@/views/WelfareForCalcList.vue")
      },
      {
        path: "/shift-lock",
        name: "ShiftLock",
        meta: { title: "シフト表ロック" },
        component: () => import("@/views/ShiftLock.vue"),
        props: true
      },
      {
        path: "/transportation-expenses",
        name: "TransportationExpenses",
        meta: { title: "交通費マスタ" },
        component: () => import("@/views/TransportationExpenses.vue"),
        props: true
      },
      {
        path: "/authinfo",
        name: "AuthInfoList",
        meta: { title: "ログインアカウント" },
        component: () => import("@/views/AuthInfoList.vue")
      }
    ]
  },
  {
    path: "/user",
    name: "UserTop",
    meta: { title: "個人勤怠登録" },
    component: () => import("@/views/UserTop.vue")
  },
  {
    path: "/user-roster",
    name: "UserRoster",
    meta: { title: "個人勤務実績" },
    component: () => import("@/views/UserRoster.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      public: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(beforeEach);

export default router;
