<template>
  <div class="home">
    <!-- AppBar -->
    <v-app-bar color="teal lighten-3" dark clipped-left app class="no-print">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{
        $store.state.title ? $store.state.title : "勤怠管理システム"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- TMPアバターチップ -->
      <v-chip
        v-if="$store.state.type === UserType.Office"
        class="ma-2"
        color="primary"
        text-color="white"
        @click="tmpLogin"
      >
        <template v-if="!$store.state.user.login">
          <v-avatar left>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          ログイン
        </template>

        <template v-else>
          <v-avatar left class="blue darken-4">
            {{ $store.state.user.name.charAt(0) }}
          </v-avatar>
          {{ $store.state.user.name }}
        </template>
      </v-chip>

      <!-- メインアバターチップ -->
      <v-chip class="ma-2" color="primary" text-color="white">
        <v-avatar v-if="$store.state.type === UserType.Office" left>
          <v-icon>mdi-office-building</v-icon>
        </v-avatar>
        <v-avatar v-else left class="blue darken-4">
          {{ $store.state.name.charAt(0) }}
        </v-avatar>
        {{ $store.state.name }}
      </v-chip>
    </v-app-bar>

    <!-- Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      clipped
      absolute
      app
      fixed
      :temporary="$vuetify.breakpoint.mobile"
    >
      <ListMenu v-model="menus" />
    </v-navigation-drawer>

    <!-- Main -->
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <!-- Main End -->

    <!-- Footer -->
    <!-- <v-footer absolute padless>
      <v-card
        width="100%"
        color="red lighten-1"
        class="text-center"
        flat
        tile
        dark
      >
        <a href="/about" style="color:#ffffff;text-decoration:none">
          {{ new Date().getFullYear() }} — <strong>Himeginsoft .inc</strong>
        </a>
      </v-card>
    </v-footer> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";

import UserType from "@/commons/usertype";

import TmpLogin from "./dialogs/TemporaryLogin";

import menus from "@/commons/menus";

export default {
  name: "Home",
  mixins: [Common, ShowDialogs],
  components: {
    ListMenu: () => import("@/components/ListMenu")
  },
  data() {
    return {
      UserType,
      drawer: false,
      menus
    };
  },
  methods: {
    logout() {
      console.log("logout");
    },
    click(e) {
      console.log(e);
    },
    async tmpLogin() {
      console.log(this.$store);
      if (!this.$store.state.user.login) await this.$showDialog(TmpLogin);
      else if (await this.$confirm("ログアウトしますか？"))
        this.$store.commit("user/logout");
    }
  },
  created() {
    console.log("vuetify.breakpoint", this.$vuetify.breakpoint);
    console.log(menus);
  }
};
</script>
