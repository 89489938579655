import { Component } from "vue-property-decorator";

import Vue from "vue";

import Confirm from "@/views/dialogs/ConfirmDialog.vue";
// import AttendanceFix from "@/views/dialogs/AttendanceFixDialog.vue";
// import TemporaryLogin from "@/views/dialogs/TemporaryLogin.vue";
import ReadQr from "@/views/dialogs/QrDialog.vue";

import Loading from "@/components/LoadingOverlay.vue";

enum Types {
  Confirm = "confirm",
  Info = "info",
  Delete = "delete",
  Warning = "warning",
  Error = "error"
}

function showDialog<T>(
  parent: Vue,
  dialog: any,
  props: object = {}
): Promise<T> {
  return new Promise<T>(resolve => {
    const propsData = { ...props, resolve };
    const VM = Vue.extend(dialog);
    new VM({
      parent,
      propsData
    });
  }).catch(err => {
    throw err;
  });
}

/**
 *
 * @param parent 親コンポーネント
 * @param message 表示メッセージ
 * @param type 確認タイプ
 */
function confirm(parent: Vue, message: string, type: Types, title: string) {
  const props = { title, message, type };
  return showDialog<boolean>(parent, Confirm, props);
}

let loading: any = null;

// @Component({
//   components: {
//     Btn: () => import("@/components/Button.vue")
//   }
// })
export default //class Common extends Vue {
Vue.extend({
  name: "ShowDialogs",
  methods: {
    /************************
     * Dialogs
     ***********************/
    /**
     * ダイアログを表示する
     * @param dialog ダイアログオブジェクト
     * @param props プロパティ
     */
    $showDialog<T = boolean>(dialog: any, props: object = {}): Promise<T> {
      return showDialog<T>(this, dialog, props);
    },
    /**
     * 確認ダイアログを表示する
     * @param message 確認メッセージ
     * @param title キャプション
     */
    $confirm(message: string, title: string): Promise<boolean> {
      return confirm(this, message, Types.Confirm, title);
    },
    /**
     * 削除確認ダイアログを表示する
     * @param message メッセージ
     * @param title キャプション
     */
    $deleteConfirm(message: string, title: string): Promise<boolean> {
      if (!message) message = "削除します。\nよろしいですか？";
      return confirm(this, message, Types.Delete, title);
    },
    /**
     * 情報ダイアログを表示する
     * @param message メッセージ
     * @param title キャプション
     */
    $info(message: string, title: string): Promise<boolean> {
      return confirm(this, message, Types.Info, title);
    },
    /**
     * 警告ダイアログを表示する
     * @param message メッセージ
     * @param title キャプション
     */
    $warning(message: string, title: string): Promise<boolean> {
      return confirm(this, message, Types.Warning, title);
    },
    /**
     * エラーダイアログを表示する
     * @param message メッセージ
     * @param title キャプション
     */
    $error(message: string, title: string): Promise<boolean> {
      return confirm(this, message, Types.Error, title);
    },
    // /**
    //  * 勤怠修正ダイアログを表示する
    //  * @param args 勤怠オブジェクト
    //  */
    // $AttendanceFix(args: object = {}): Promise<boolean> {
    //   return showDialog(this, AttendanceFix, { args });
    // },
    // /**
    //  * 仮ログインダイアログを表示する。
    //  * ログイン済みの場合は表示しない。
    //  */
    // async $tmpLogin(): Promise<boolean> {
    //   if (this.$store.state.user.login) return true;

    //   return await showDialog(this, TemporaryLogin);
    // },
    /**
     * 仮ログインダイアログを表示する。
     * ログイン済みの場合は表示しない。
     */ async $ReadQR(): Promise<string> {
      return await showDialog<string>(this, ReadQr);
    },
    $loading(timeout: number | null = null): Promise<void> {
      return new Promise<void>(resolve => {
        const propsData = { timeout, resolve };
        const VM = Vue.extend(Loading);
        loading = new VM({
          parent: this,
          propsData
        });
      }).catch(err => {
        throw err;
      });
    },
    $unloading(): void {
      if (loading) loading.close();
      loading = null;
    }
  }
});
