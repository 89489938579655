import Vue from "vue";

export default Vue.extend({
  name: "Common",
  components: {
    Btn: () => import("../components/Button.vue"),
    MonthPicker: () => import("@/components/MonthPicker.vue"),
    DatePicker: () => import("@/components/DatePicker.vue"),
    TimePicker: () => import("@/components/TimePicker.vue")
  },
  computed: {
    $isLogin(): boolean {
      return this.$store.state.login;
    },
    $isTmpLogin(): boolean {
      return this.$store.state.user.login;
    }
  },
  methods: {
    $log(data: any): void {
      console.log(`log`, data);
    },
    $isEmpty(obj: any, ...exclusions: Array<any>): boolean {
      for (const exclusion of exclusions) if (obj === exclusion) return false;

      if (typeof obj === "object")
        if (Array.isArray(obj)) return !obj.length;
        else return !Object.keys(obj).length;
      else return !obj;
    },
    $getCrossServiceDate(): string {
      const nowDt = new Date();
      let result = "";
      let add = 1;
      if (nowDt.getDate() >= 21) {
        add += 1;
      }

      if (Number(nowDt.getMonth() + add) == 13) {
        result = String(nowDt.getFullYear() + 1 + "-" + "01" + "-" + "01");
      } else {
        result = String(
          nowDt.getFullYear() +
            "-" +
            ("00" + Number(nowDt.getMonth() + add)).slice(-2) +
            "-" +
            "01"
        );
      }

      return result;
    }
  }
});
